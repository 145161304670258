import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, {useEffect, useState} from "react";
import {useApi} from "../../services/helpers";
import DataTableSimple from "../../components/DataTableSimple";
import moment from "moment";

export const dataTableModel = {
  columns: [
    {Header: "Created by", accessor: "created_by" },
    {Header: "Created at", accessor: "created_at" },

    {Header: "Lead Name", accessor: "full_name" },
    {Header: "Lead Source", accessor: "source" },
    {Header: "Time to Reach", accessor: "contact_time" },

    {Header: "LD opportunity id", accessor: "ld_opportunity_id" },
    {Header: "LD lead id", accessor: "ld_lead_id" },
    {Header: "LD project status", accessor: "ld_project_status" },
    {Header: "FV project id", accessor: "fv_project_id" },
    {Header: "FV project status", accessor: "fv_project_status" },
  ],
  rows: [],
};

function AdminLeads() {
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const [table, setTable] = useState(dataTableModel);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
  });
  
  const getLeads = () => {
    setLoading(true);
    api.getLeads().handle({
      onSuccess: (result) => {
        console.log("result: ", result);
        const results = result?.data;
        const data = results.map((d) => ({
          ...d,
          created_at: moment(d.created_at).format('MM/DD/YYYY'),
          contact_time: moment(d.contact_time).format('MM/DD/YYYY hh:mm'),
          ld_opportunity_id: d.ld_opportunity_id || ' - ',
          ld_lead_id: d.ld_lead_id || ' - ',
          ld_project_status: d.ld_project_status || ' - ',
          fv_project_id: d.fv_project_id || ' - ',
          fv_project_status: d.fv_project_status || ' - ',
        }));

        setTable({...dataTableModel, rows: data});
        setPaginationData({
          counts: results.length,
          itemsPerPage: results.length,
          currentPage: 1,
        });
      },
      errorMessage: "Error getting leads",
      onError: (err) => {
        console.log(err);
      },
      onFinally: () => setLoading(false),
    });
  };


  useEffect(() => {
    getLeads();
  }, []);

  return (
    <AdminLayout title={"Admin Signed Leads Dash"}>
      <MDBox width={"100%"}>
        <MDBox>
          <DataTableSimple table={table} />
        </MDBox>
      </MDBox>
    </AdminLayout>
  );
}

export default AdminLeads;
